import classNames from "classnames";
import { format } from "date-fns";
import { useMemo, useState, type FunctionComponent } from "react";
import { IoIosBatteryFull, IoIosWifi } from "react-icons/io";
import { useInterval } from "usehooks-ts";
import DownloadAppStore from "~/public/images/download-app-store.svg";
import DownloadGooglePlay from "~/public/images/download-google-play.svg";

const preventTab: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
  if (event.key === "Tab") {
    event.preventDefault();
  }
};

export interface PhoneMockupProps {
  phoneScale: number;
  phoneWidth: number;
  phoneHeight: number;
  children: React.ReactNode;
}

export const PhoneMockup: FunctionComponent<PhoneMockupProps> = ({
  children,
  phoneScale,
  phoneHeight,
  phoneWidth,
}) => {
  const clxMockup = classNames(
    "mockup-phone",
    "border-secondary",
    "shrink-0",
    "grow-0",
    "m-0",
  );

  const phoneStyle = useMemo(() => {
    return {
      width: Math.round(phoneWidth * phoneScale),
      height: Math.round(phoneHeight * phoneScale),
    };
  }, [phoneHeight, phoneScale, phoneWidth]);

  const [time, setTime] = useState(format(new Date(), "HH:mm"));

  useInterval(() => {
    setTime(format(new Date(), "HH:mm"));
  }, 1000);

  return (
    <div className="flex items-center gap-16">
      <div className={clxMockup} onKeyDown={preventTab}>
        <div className="camera"></div>
        <div
          className={`display transform overflow-hidden bg-white pt-7`}
          style={phoneStyle}
        >
          <div className="fixed left-5 top-2 inline-flex text-overline-2">
            <p className="text-flowpass-purple-darkest">{time}</p>
          </div>
          <div className="fixed right-4 top-2 inline-flex">
            <IoIosWifi className="mr-1 h-5 w-5 fill-flowpass-purple-darkest" />
            <IoIosBatteryFull className="h-5 w-5  fill-flowpass-purple-darkest" />
          </div>

          {children}
        </div>
      </div>
      <article className="flex max-w-96 flex-col gap-6 text-left">
        <h1 className="text-headline-1">Welcome to Flowpass on desktop</h1>
        <p className="text-subline-2">
          Here you can use Flowpass on your desktop...
        </p>

        <p className="text-subline-2">
          ...or download our app to use on your phone. Available on:
        </p>

        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-5">
            <a
              className="inline-block"
              style={{ width: 120, height: 40 }}
              href="https://apps.apple.com/app/flowpass/id1569142361"
              target="_blank"
              rel="noreferrer"
            >
              <DownloadAppStore />
            </a>

            <a
              className="inline-block"
              style={{ width: 130, height: 40 }}
              href="https://play.google.com/store/apps/details?id=co.flowpass.app.twa"
              target="_blank"
              rel="noreferrer"
            >
              <DownloadGooglePlay />
            </a>
          </div>
        </div>

        <footer>
          <p className="mb-3 text-body-3">
            Do you have questions or feedback? We are looking forward to hearing
            from you!
          </p>
          <p className="text-body-3">
            Please reach out here:{" "}
            <a
              href="mailto:cs@flowpass.co"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              cs@flowpass.co
            </a>
          </p>
        </footer>
      </article>
    </div>
  );
};
