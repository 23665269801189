"use client";
import React, { ReactNode } from "react";
import { StatsigProvider } from "statsig-react";
import { useMe } from "~/hooks/useMe";

const SDK_KEY = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY;

export const FPStatsigProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { me } = useMe();
  const user = {
    userID: me?.id,
    email: me?.email,
    customIDs: {
      organizationId: me?.organization?.id || null,
    },
  };

  return (
    <StatsigProvider sdkKey={SDK_KEY} user={user} waitForInitialization={true}>
      {children}
    </StatsigProvider>
  );
};
